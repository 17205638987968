import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import { FiChevronRight } from 'react-icons/fi';

const query = graphql`
	query NavQuery {
		pages: allSanityPage {
			edges {
				node {
					id
					_id
					title
					slug {
						current
					}
				}
			}
		}
	}
`

const Nav = ({ onLinkClick, activeLinkClassName, displayArrow, arrowClassName }) => {
	return (
		<StaticQuery
			query={query}
			render={data => {
				return (
					<ul>
						<li>
							<Link to={`/`} onClick={onLinkClick} activeClassName={activeLinkClassName}>
								Home
								{displayArrow && <FiChevronRight className={arrowClassName} />}
							</Link>
						</li>
						{data.pages.edges.map(edge => (
							<li key={edge.node.id}>
								<Link to={`/${edge.node.slug.current}`} onClick={onLinkClick} activeClassName={activeLinkClassName}>
									{edge.node.title}
									{displayArrow && <FiChevronRight className={arrowClassName} />}
								</Link>
							</li>
						))}
					</ul>
				)
			}}
		/>
	)
}

export default Nav
