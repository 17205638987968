import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Container from "react-bulma-components/lib/components/container"
import Columns from "react-bulma-components/lib/components/columns"
import Heading from "react-bulma-components/lib/components/heading"
import Section from "react-bulma-components/lib/components/section"
import Nav from "./nav"
import { FiInstagram } from "react-icons/fi"
import styles from "./footer.module.css"

const query = graphql`
	query FooterQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			title
		}
		companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
			name
			address1
			address2
			zipCode
			city
			instagram
		}
		pages: allSanityPage {
			edges {
				node {
					id
					_id
					title
					slug {
						current
					}
				}
			}
		}
	}
`

const Footer = props => {
	return (
		<StaticQuery
			query={query}
			render={data => {
				return (
					<Section renderAs="footer" className={styles.root}>
						<Container className={styles.container}>
							<Columns>
								<Columns.Column size={7}>
									<Heading size={4} renderAs="h3" className={styles.title}>
										{data.site.title}
									</Heading>
									<p className={styles.text}>{data.companyInfo.name}</p>
									<p className={styles.textFirst}>{data.companyInfo.address1}</p>
									<p className={styles.text}>{data.companyInfo.address2}</p>
									<p className={styles.text}>{data.companyInfo.zipCode}</p>
									<p className={styles.text}>{data.companyInfo.city}</p>
								</Columns.Column>
								<Columns.Column size={3} className={styles.nav}>
									<Heading size={4} className={styles.title} renderAs="h3">
										Company
									</Heading>
									<Nav activeLinkClassName={styles.activeLinkClassName} />
								</Columns.Column>
								<Columns.Column size={2} className={styles.companyInfo}>
									<Heading size={4} className={styles.title} renderAs="h3">
										Social
									</Heading>
									<a
										href={`https://instagram.com/${data.companyInfo.instagram}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										<FiInstagram />
										<span>{data.companyInfo.instagram}</span>
									</a>
								</Columns.Column>
							</Columns>
						</Container>
					</Section>
				)
			}}
		/>
	)
}

export default Footer
