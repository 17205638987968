import React from "react"
import { Helmet } from "react-helmet-async"
import { StaticQuery, graphql } from "gatsby"

const detailsQuery = graphql`
	query SEOQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			title
			description
			keywords
			shareImage {
				alt
				asset {
					mainImage: fluid(maxWidth: 1920) {
						src
					}
				}
			}
		}
	}
`

function SEO({
	description,
	lang = "en",
	image: imageFromProps,
	url,
	imageAlt: imageAltFromProps,
	meta = [],
	keywords = [],
	title: titleFromProps,
}) {
	return (
		<StaticQuery
			query={detailsQuery}
			render={(data) => {
				const image = imageFromProps || data.site.shareImage.asset.mainImage?.src
				const imageAlt =
					image === data.site.shareImage.asset.mainImage?.src ? data.site.shareImage.alt : imageAltFromProps
				const title = titleFromProps || data.site.title
				const metaDescription = description || data.site.description
				return (
					<Helmet
						htmlAttributes={{
							lang,
						}}
						title={title}
						titleTemplate={title === data.site.title ? "%s" : `%s - ${data.site.title}`}
						meta={[
							{
								name: "description",
								content: metaDescription,
							},
							{
								property: "og:title",
								content: title,
							},
							{
								property: "og:description",
								content: metaDescription,
							},
							{
								property: "og:type",
								content: "website",
							},
							{
								property: `og:image`,
								content: image,
							},
							{
								property: `og:image:alt`,
								content: imageAlt,
							},
							{
								property: `og:url`,
								content: url,
							},
							{
								name: "twitter:card",
								content: "summary",
							},
							{
								name: "twitter:creator",
								content: data.site.title,
							},
							{
								name: "twitter:title",
								content: title,
							},
							{
								name: "twitter:description",
								content: metaDescription,
							},
							{
								name: `twitter:image`,
								content: image,
							},
							{
								name: `twitter:image:alt`,
								content: imageAlt,
							},
						]
							.concat(
								keywords && keywords.length > 0
									? {
											name: "keywords",
											content: keywords.join(", "),
									  }
									: []
							)
							.concat(meta)}
					/>
				)
			}}
		/>
	)
}

export default SEO
