import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import SEO from "../components/seo"
import "normalize.css/normalize.css"
import "../styles/global.css"

const LayoutDefault = ({ children }) => {
	const data = useStaticQuery(graphql`
		query DefaultLayoutQuery {
			site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
				title
				description
				keywords
				shareImage {
					alt
					asset {
						mainImage: fluid(maxWidth: 1920) {
							src
						}
					}
				}
			}
		}
	`)

	return (
		<>
			<SEO
				title={data.site.title}
				keywords={data.site.keywords}
				description={data.site.descriptio}
				image={data.site.shareImage.asset.mainImage.src}
				imageAlt={data.site.shareImage.alt}
			/>
			<Header />
			<main>{children}</main>
			<Footer />
		</>
	)
}

export default LayoutDefault
