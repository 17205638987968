import { graphql, Link, StaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import Container from "react-bulma-components/lib/components/container"
import Navbar from "react-bulma-components/lib/components/navbar"
import { ShowContext } from "react-bulma-components/lib/components/navbar/context"
import { cn } from "../lib/helpers"
import styles from "./header.module.css"
import Nav from "./nav"

const query = graphql`
	query HeaderQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			title
		}
		pages: allSanityPage {
			edges {
				node {
					id
					_id
					title
					slug {
						current
					}
				}
			}
		}
	}
`

function Header(data) {
	const [showNav, setNavVisibility] = useState(false)

	useEffect(() => {
		const doWork = () => {
			if (window.innerWidth >= 720) {
				setNavVisibility(false)
			}
		}
		window.addEventListener('resize', doWork)
		return () => {
			window.removeEventListener("resize", doWork)
		}
	})

	return (
		<header className={styles.root}>
			<Container breakpoint="fullhd">
				<div className={styles.container}>
					<h1 className={styles.branding}>
						<Link to="/">{data.site.title}</Link>
					</h1>
					<nav className={cn(styles.nav, showNav && styles.showNav)}>
						<div className={styles.innerNav}>
							<Nav
								displayArrow
								arrowClassName={styles.arrow}
								activeLinkClassName={styles.activeLinkClassName}
								onLinkClick={() => setNavVisibility(false)}
							/>
							<button
								className={cn(styles.openNavButton)}
								onClick={() => setNavVisibility(false)}
							>
								<ShowContext.Provider value={true}>
									<Navbar.Burger className={styles.closeBurger} />
								</ShowContext.Provider>
							</button>
						</div>
					</nav>
					<button
						className={cn(styles.closeNavButton)}
						onClick={() => setNavVisibility(true)}
					>
						<ShowContext.Provider value={false}>
							<Navbar.Burger className={styles.openBurger} />
						</ShowContext.Provider>
					</button>
				</div>
			</Container>
		</header>
	)
}

const Page = () => {
	return <StaticQuery query={query} render={Header} />
}

export default Page
